import React from 'react';
import styled from '@emotion/styled';
import { NavigationControl } from 'react-map-gl';
import { device } from '../../constants';

const NavigationContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 30px;

  z-index: 700;

  padding: 15px;
  padding-bottom: 55px;

  @media ${device.tablet} {
    padding-bottom: 35px;
  }
`;

export default () => (
  <NavigationContainer>
    <NavigationControl showCompass={false} />
  </NavigationContainer>
);
