import Axios from 'axios';
import MapGL from 'react-map-gl';
import React from 'react';
import Marker from './Marker';
import UserMarker from './UserMarker';
import NavigationControl from './NavgationControl';
import Popup from './Popup';
import Header from '../Header';

export default class extends React.Component {
  constructor(props) {
    super(props);

    const { latitude, longitude, zoom } = props;

    this.state = {
      popup: null,
      userLocation: null,
      viewport: {
        latitude,
        longitude,
        zoom,
      },
      markers: undefined,
    };
  }

  async componentDidMount() {
    const res = await Axios.get(`/.netlify/functions/getBakeries`);
    if (res.data) await this.setState({ markers: res.data });

    this.locateUser();
  }

  locateUser() {
    navigator.geolocation.getCurrentPosition(position => {
      this.setState({
        userLocation: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        },
        viewport: {
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
          zoom: 13,
        },
      });
    });
  }

  setPopup = popup => this.setState({ popup });
  clearPopup = () => this.setState({ popup: null });

  render() {
    const { viewport, popup, markers, userLocation } = this.state;

    const mapMarkers =
      Array.isArray(markers) && markers.length > 0
        ? markers.map(
            location => new Marker({ onClick: this.setPopup, ...location }),
          )
        : undefined;

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={v => this.setState({ viewport: v })}
        mapboxApiAccessToken={this.props.token}
        mapStyle={this.props.style}
      >
        {userLocation && <UserMarker {...userLocation} />}
        <Header />
        <NavigationControl />

        {mapMarkers}
        {popup && <Popup {...popup} onClose={this.clearPopup} />}
      </MapGL>
    );
  }
}
