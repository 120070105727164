import React from 'react';
import { Global, css } from '@emotion/core';
import 'typeface-poppins';

export default ({ children }) => (
  <>
    <Global
      styles={css`
        body {
          background-color: white;
          margin: 0px;
        }
      `}
    />
    {children}
  </>
);
