import styled from '@emotion/styled';
import { device } from '../../../constants';

export const Container = styled.div`
  font-family: 'Poppins';

  bottom: 0;

  position: absolute;

  background-color: white;
  z-index: 900;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.tablet} {
    width: 25vw;

    top: 0;
    left: 0;
    bottom: auto;

    height: auto;

    border: 1px solid black;
    border-radius: 3px;
    background-color: white;

    margin: 10px;
  }
`;

export const Header = styled.div`
  margin-left: 10px;

  display: flex;
  flex-direction: column;
`;

export const Address = styled.a`
  font-size: 14px;

  color: black;

  margin-top: 10px;
  margin-bottom: 10px;
`;

export const StoreType = styled.p`
  margin: 0px;
`;

export const Title = styled.h2`
  font-size: 20px;

  margin-bottom: 0px;
`;

export const Star = styled.p`
  font-size: 18px;

  margin: 0px;
`;

export const Photo = styled.img`
  width: 100%;
  object-fit: cover;

  max-height: 200px;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;

  z-index: 900;

  display: flex;
  align-items: center;
  justify-content: center;

  color: black;
  background-color: white;
  border-radius: 3px;

  font-size: 16px;

  width: 35px;
  height: 35px;
`;
