import React from 'react';
import styled from '@emotion/styled';
import dropPin from './store.svg';
import { device } from '../../../constants';

const Location = styled.div`
  display: flex;
  z-index: 1;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  height: 100%;

  .DropPin {
    width: 30px;
    height: 30px;

    @media ${device.laptopL} {
      width: 35px;
      height: 35px;
    }
  }
`;

export default ({ onClick, ...props }) => (
  <Location onClick={() => onClick(props)} {...props}>
    <img className="DropPin" src={dropPin} alt="DropPin" />
  </Location>
);
