import _ from 'lodash';
import React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/Layout';
import Head from '../components/Head';
import MapBox from '../components/Map';

const MapContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const schema = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  inLanguage: 'en',
  name: `Map of London's Best Bakeries`,
  url: 'https://bakerymap.com',
  description: 'Best map of bakeries in London',
  keywords: ['bakery', 'bread', 'map', 'london'],
  sameAs: ['https://www.instagram.com/thebakerymap'],
};

export default ({ pageContext }) => {
  const { mapBoxToken, mapBoxStyle } = pageContext;

  return (
    <Layout>
      <Head schema={schema} />
      <MapContainer>
        <MapBox
          latitude={51.5202}
          longitude={-0.1048}
          token={mapBoxToken}
          style={mapBoxStyle}
          zoom={13}
        />
      </MapContainer>
    </Layout>
  );
};
