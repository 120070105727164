export const localisation = 'en-GB';

export const color = {
  powederBlue: '#BAE0D4',
  veryPaleOrange: '#FFCCBF',
  darkCyan: '#078786',
  maastritchBlue: '#011638',
};

export const fontWeight = {
  light: 300,
  regular: 400,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

export const size = {
  mobileS: '320',
  mobileM: '375',
  mobileL: '425',
  tablet: '768',
  laptop: '1024',
  laptopL: '1440',
  desktop: '2560',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop}px)`,
  desktopL: `(min-width: ${size.desktop}px)`,
};
