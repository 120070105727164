import React from 'react';
import styled from '@emotion/styled';
import Instagram from '../assets/instagram.png';

const Header = styled.header`
  position: absolute;
  top: 0;
  right: 0;

  padding-right: 20px;

  z-index: 800;

  background-color: white;

  border-bottom-left-radius: 4px;

  border: solid 1.5px black;
  border-top: 0px;
  border-right: 0px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const H1 = styled.h1`
  color: black;
  font-family: 'Poppins';

  font-size: 22px;

  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
`;

const InstagramLogo = styled.img`
  height: 25px;
  width: 25px;

  margin-left: 10px;
`;

export default () => (
  <Header>
    <H1>Bakery Map</H1>
    <a href="https://instagram.com/thebakerymap" target="_blank">
      <InstagramLogo src={Instagram} />
    </a>
  </Header>
);
