import { Marker } from 'react-map-gl';
import React from 'react';
import Pin from './Pin';

export default props => {
  const { latitude, longitude } = props;
  return (
    <Marker
      key={`marker-${latitude}-${longitude}`}
      longitude={longitude}
      latitude={latitude}
    >
      <Pin {...props} />
    </Marker>
  );
};
