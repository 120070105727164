export const ExtractPhotoURL = photo => {
  if (!Array.isArray(photo) || photo.length === 0) return;
  const firstItem = photo[0];
  if (!firstItem.type || firstItem.type !== 'image/jpeg') return;
  return firstItem.url;
};

export const GenerateGoogleMapsURL = ({ address, suburb, postCode }) => {
  const fullAddress = `${address} ${suburb} ${postCode}`.replace(/ /g, '+');
  return `https://www.google.com/maps?daddr=${fullAddress}`;
};
