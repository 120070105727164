import React from 'react';
import {
  Container,
  Header,
  Address,
  StoreType,
  Title,
  Star,
  Photo,
  CloseButton,
} from './components';
import { ExtractPhotoURL, GenerateGoogleMapsURL } from './helpers';

const Rating = rating => <Star>{'\u2b50'.repeat(rating)}</Star>;

export default props => {
  const { name, address, suburb, postCode, rating, photo, storeType } = props;
  const photoURL = photo && ExtractPhotoURL(photo);

  return (
    <Container>
      {photoURL && <Photo src={photoURL} />}
      <CloseButton onClick={props.onClose}>X</CloseButton>
      <Header>
        <Title>{name}</Title>
        <StoreType>{storeType}</StoreType>
        {rating && Rating(rating)}
        <Address target="_blank" href={GenerateGoogleMapsURL(props)}>
          {address}, {suburb}, {postCode}
        </Address>
      </Header>
    </Container>
  );
};
