import React from 'react';
import Helmet from 'react-helmet';

export default ({ schema }) => {
  const keywordsMeta = Array.isArray(schema.keywords)
    ? {
        name: `keywords`,
        content: schema.keywords.join(`, `),
      }
    : [];

  const meta = [
    {
      name: `description`,
      content: schema.description,
    },
    {
      property: `og:title`,
      content: schema.title,
    },
  ].concat(keywordsMeta);

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={schema.title}
        meta={meta}
      />
      {schema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema, null, 4),
          }}
        />
      )}
    </>
  );
};
