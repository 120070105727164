import { Marker } from 'react-map-gl';
import React from 'react';

export default ({ latitude, longitude }) => (
  <Marker
    key="location-marker"
    className="mapboxgl-user-location-dot"
    longitude={longitude}
    latitude={latitude}
    onContextMenu={e => e.preventDefault()}
    captureDrag={false}
    captureDoubleClick={false}
  />
);
